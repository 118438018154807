import { rdrRequests, rdRequests, authorizedRDRequests } from "../httpRequest";

const activityApiRequests = {
  getProgramAndTourDetails: (sessionParameters: any) => rdRequests.post(`WebAccessActivity/Load/ProgramAndTourDetails`, sessionParameters),
  searchSessionData: (values: any, searchPage: number) => rdrRequests.post(`AD/SearchSession?searchPage=${searchPage}`, values),
  getActivityDetails: (sessionParameters: any) => authorizedRDRequests.post(`WebAccessActivity/load/LoadActivityDetails`, sessionParameters),
  allowSessionSignupWindow: (sessionSignupWindowParameter: any) => rdRequests.post(`WebAccessActivity/AllowSessionSignupWindow`, sessionSignupWindowParameter),
  getInstructor: (customerName: string) => rdRequests.get(`WebAccessActivity/GetInstructor?customerName=${customerName}`),
  getSignUpPrecartDetails: (signUpPreCartParameters: any) => authorizedRDRequests.post(`WebAccessActivity/Load/SignUpPrecartDetails`, signUpPreCartParameters),
  validateActivitySignUp: (activitySignUp: any) => authorizedRDRequests.post(`WebAccessActivity/ActivitySignUp`, activitySignUp),
  getProgramSignUpDetails: (programSignUpRequest: any) => rdRequests.post(`WebAccessActivity/GetProgramSignUpDetails`, programSignUpRequest),
  getSelectedMeetingTimesForProgram: (programSignUpRequest: any) => rdRequests.post(`WebAccessActivity/GetSelectedMeetingTimesForProgram`, programSignUpRequest),
  sendTellAFriendMessage: (requestData: any) => rdRequests.post(`WebAccessActivity/SendTellAFriendMessage`, requestData),
  processSignUpModification: (signUpModification: any) => authorizedRDRequests.post(`WebAccessActivity/ActivitySignUpModification`, signUpModification),
  getSessionQuestionAnswers: (sessionId: any, confirmationNumber: any, signUpCustomerId:any) => authorizedRDRequests.get(`WebAccessActivity/GetSessionQuestionAnswers?sessionId=${parseInt(sessionId)}&confirmationNumber=${parseInt(confirmationNumber)}&signUpCustomerId=${parseInt(signUpCustomerId)}`),
  processActivityUpdateInfo: (sessionQuestionAnswers: any) => authorizedRDRequests.post(`WebAccessActivity/SaveSessionQuestionAnswers`, sessionQuestionAnswers),
  getAlertDetailForActivity: (requestData: any) => rdRequests.post(`WebAccessActivity/GetAlertDetailForActivity`, requestData),
  getpopulatetimeslot: (requestData: any) => authorizedRDRequests.post(`WebAccessActivity/GetPopulateTimeSlot`, requestData),
  getseatvalues: (requestData: any) => authorizedRDRequests.post(`WebAccessActivity/GetSeatValues`, requestData),
  getHearstCastleTourDetails: (storeId: number) => rdRequests.get(`WebAccessActivity/Load/HearstCastleTourDetails?storeId=${storeId}`),  
  getSignUpPrecartHearstCastleDetails: (signUpPreCartParameters: any) => authorizedRDRequests.post(`WebAccessActivity/Load/SignUpPrecartHearstCastleDetails`, signUpPreCartParameters),
  getTourSessionData: (storeId:any, categoryId: any, subCategoryId:any, selectedDate:any) => rdrRequests.get(`AD/SearchTourSession/?storeId=${storeId}&categoryId=${categoryId}&subCategoryId=${subCategoryId}&searchDate=${selectedDate}`),																																																											
  validateHeastCastleActivitySignUp: (activitySignUp: any) => authorizedRDRequests.post(`WebAccessActivity/HeastCastleActivitySignUp`, activitySignUp),
  getActivityDescriptionBySubcategory: (subCategoryId: number) => rdRequests.get(`WebAccessActivity/ActivityDescriptionBySubcategory?subCategoryId=${subCategoryId}`),

}

export default activityApiRequests;