import { rdrRequests , authorizedRDRequests} from "../httpRequest";

const drawApiRequest = {
    getDrawApplicationData: (placeId: number, facilityId: number, startDate: string, applicationId: number) => applicationId > 0 ? rdrRequests.get(`search/application/${applicationId}`) : rdrRequests.get(`search/application/${placeId}/${facilityId}/startdate/${startDate}`),
    getPlaceList: () => rdrRequests.get(`fd/places`),
    getCustomerDrawApplicationData: (requestData: any) => rdrRequests.post(`search/applicationsearch`, requestData),
    checkCustomerDrawApplication: (requestData: any) => rdrRequests.get(`search/applicationcheck/${requestData.applicationId}/${requestData.customerId}/${requestData.facilityId}/startdate/${requestData.startDate}`),
    getActiveDrawList: () => rdrRequests.get(`search/activedrawapplication`),
    saveDrawApplication: (values: any) => authorizedRDRequests.post(`webaccessfacility/Save/CustomerDrawApplication`, values),
    cancelDrawApplication: (values: any) => authorizedRDRequests.post(`webaccessfacility/Cancel/CustomerDrawApplication`, values),
    bookDrawApplication: (values: any) => authorizedRDRequests.post(`webaccessfacility/Book/DrawApplication`, values),
    rediectDrawToPreCart: (applicationCustomerDetailId: any) => authorizedRDRequests.post(`webaccessfacility/Redirect/DrawToPreCart`,applicationCustomerDetailId)
}

export default drawApiRequest;