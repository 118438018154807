import { authorizedRDRequests, rdRequests } from "../httpRequest";

const passesApiRequest = {
  getPassHolderNames: () => authorizedRDRequests.get(`Pass/GetPassHolderNames`),
  getListOfPasses: (selectedCustomerId: number) => rdRequests.get(`Pass/GetListOfPassFromPassHolder?SelectedCustomerId=${selectedCustomerId}`),
  getCustomerAddressDetail: () => authorizedRDRequests.post(`Pass/CustomerAddressDetail`,{}),
  getDayUsePassDetails: (requestData: any) => rdRequests.post(`Pass/load/dayusepassdetail`, requestData),
  getDayUsePassDetailOnPlaceChange: (requestData: any) => rdRequests.post(`Pass/dayusepassbyplaceid`, requestData),
  getIsZipCodeValid: (zipCode: string | undefined) => rdRequests.get(`Pass/IsValidZipCode?zipCode=${zipCode}`),
  addToPassInCart: (requestData: any) => authorizedRDRequests.post(`Pass/MembershipAddToCart`, requestData),
  getSelectedPassDetails: ( passId: number,selectedCustomerId:string) => rdRequests.get(`Pass/GetSelectedPassInformation?passId=${passId}&selectedCustomerId=${selectedCustomerId}`),
  processPassesCartDetails: (passesParameters: any) => authorizedRDRequests.post(`Pass/ValidatePassesCartDetails`, passesParameters),
  maxPassLimitAndPromoCode: (passesParameters: any) => authorizedRDRequests.post(`Pass/CheckPassLimitAndPromoCode`, passesParameters),
  checkVirtualCartOverlapping: (passesParameters: any) => authorizedRDRequests.post(`Pass/CheckVirtualCartOverlapping`, passesParameters),
  WebActivateGiftPass: (requestParameter: any) => authorizedRDRequests.post(`Pass/WebActivateGiftPass`, requestParameter),
  getVehicles: (selectedCustomerId: number, membershipId: number) => authorizedRDRequests.get(`Pass/GetVehicles?selectedCustomerId=${selectedCustomerId}&membershipId=${membershipId}`),
  getVehicle: (vehicleId: number) => authorizedRDRequests.get(`Pass/GetVehicle?vehicleId=${vehicleId}`),
  getMemberShipPassVehicleConfigInfo: (membershipId: number) => authorizedRDRequests.get(`Pass/GetMemberShipPassVehicleConfigInfo?membershipId=${membershipId}`),
  getVehicleTypes: (membershipId: number) => authorizedRDRequests.get(`Pass/GetVehicleTypes?membershipId=${membershipId}`),
  getColors: () => authorizedRDRequests.get(`Pass/GetColors`),
  saveVehicle: (requestData: any, selectedCustomerId : number) => authorizedRDRequests.post(`Pass/SaveVehicle?selectedCustomerId=${selectedCustomerId}`, requestData),
  deleteVehicle: (vehicleId: number) => authorizedRDRequests.get(`Pass/DeleteVehicle?vehicleId=${vehicleId}`),
  saveCustomerDateOfBirthAndLicenceInfo: (requestData: any) => authorizedRDRequests.get(`WebAccessCustomer/SaveCustomerDateOfBirthAndLicenceInfo?customerId=${requestData.customerId}&last6OfId=${requestData.last6OfId}&dob=${requestData.dob}`),
}

export default passesApiRequest;